<template>
  <div class="modal fade" id="documentModal" data-backdrop="static" tabindex="-1" role="dialog"
       aria-labelledby="documentModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content bg-color-default">
        <div class="modal-header">
          <h4 slot="header" id="documentModalLabel">
            Json Enviado ao ERP
          </h4>
          <button type="button" class="close" @click="closeModal">
                        <span aria-hidden="true" class="text-white">
                            <i class="tim-icons icon-simple-remove"></i>
                        </span>
          </button>
        </div>
        <div class="modal-body">
          <card type="tasks" class="stacked-form">
            <base-input>
              <label>Documento:</label>
              <el-input v-model="documentTypeValue" placeholder="Informe o ID correspondente" disabled />
            </base-input>
            <base-input>
              <label>Json:</label>
              <el-input v-model="jsonData" type="textarea" :autosize="{ minRows: 2, maxRows: 10 }" />
            </base-input>
            <base-button type="primary" small class="btn-next" @click="getBusinessPartner()">
              Parceiro de Negócio
            </base-button>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import Modal from '@/components/Modal'

export default {
  components: {
    Modal
  },
  data() {
    return {
      documentTypeValue: null,
    }
  },
  computed: {
    ...mapState("orders", ["logInternal"]),
      ...mapState("health", ["displayRestrictedData"]),
    jsonData() {
      try {
        const parsedJSON = JSON.parse(this.logInternal.message);
        return JSON.stringify(parsedJSON, null, 2);
      } catch (error) {
        return {};
      }
    }
  },
  methods: {
    ...mapActions("orders", ["getLogInternal"]),
    async loadLog() {
      await this.getLogInternal({
        EcommerceName: this.$store.state.ecommerce.Name,
        LogType: this.$route.params.type,
        OrderNumber: this.$route.params.number,
        DisplayRestrictedData: this.displayRestrictedData
      });
      this.documentTypeValue = this.getDocumentName(this.$route.params.type);
    },
    getBusinessPartner() {
      this.getLogInternal({
        EcommerceName: this.$store.state.ecommerce.Name,
        LogType: '1',
        OrderNumber: this.$route.params.number
      });
      this.documentTypeValue = this.getDocumentName('1');
    },
    closeModal() {
      $(".modal-backdrop").hide();
      this.$router.go(-1);
    },
    closeModalOnOutsideClick(event) {
      if (document.body.classList.contains('modal-open') && !event.target.closest('.modal-dialog')) {
        this.closeModal();
      }
    },
    getDocumentName(prop) {
      switch (prop) {
        case '1':
          return 'Parceiro de Negócio';
        case '2':
          return 'Pedido';
        case '5':
          return 'Contas a Receber';
        case '6':
          return 'Nota Fiscal de Sáida';
        case '7':
          return 'Picking';
        case '8':
          return 'Adiantamento';
        case '11':
          return 'NFe Saída de Transferência';
        case '12':
          return 'Recebimento de Mercadoria Transferência';
        case '13':
          return 'NFe Entrada de Transferência';
        case '14':
          return 'Transferência de Estoque';
        case '15':
          return 'Fulfillment - Devolução NFe Saída';
      }
    },
  },
  async mounted() {
    await this.loadLog();
    setTimeout(() => {
      document.addEventListener('click', this.closeModalOnOutsideClick);
      jQuery("#documentModal").modal("show");
    }, 500);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.closeModalOnOutsideClick);
  },
}
</script>
<style scoped>
.modal-footer button {
  margin: 10;
  padding-left: 16px;
  padding-right: 16px;
  width: auto;
}

.el-button--primary.is-disabled,
.el-button--primary.is-disabled:active,
.el-button--primary.is-disabled:focus,
.el-button--primary.is-disabled:hover {
  color: #FFF;
  background-color: #7ed3d8;
  border-color: #7ed3d8;
}

.el-button:focus,
.el-button:hover {
  color: #FFF;
  border-color: #00ADB7;
  background-color: #00ADB7;
}

.el-button--primary {
  color: #FFF;
  background-color: #4eacb1;
  border-color: #4eacb1;
}

.el-switch.is-checked .el-switch__core {
  border-color: #00ADB7 !important;
  background-color: #00ADB7 !important;
}

.el-switch__label.is-active {
  color: #00ADB7 !important;
}

.el-select-dropdown__item.selected {
  color: #00ADB7;
  font-weight: 700;
}

.form-group .el-select .el-input.is-disabled .el-input__inner {
  cursor: not-allowed !important;
}

svg.iconify.iconify--mdi {
  cursor: pointer;
}
</style>
